import React from 'react';
import { graphql } from 'gatsby';
import '../fragments';
import { LawyerCharacteristics } from '../graphql-fragments/Lawyer';

import {
  renderBoolean,
  renderAreasOfExpertise,
  renderCountries,
  renderLawyerScale,
  renderPrice,
} from '../utils/characteristicsRenderFunctions';

import EntityPage from './EntityPage';

import * as styles from './EntityPage.module.scss';
import { ENTITY_TYPES_WITH_REVIEWS } from '../constants';
import { RawPortableText } from '../types';

export const query = graphql`
  query GetLawyerPageById($id: String!) {
    sanityLawyer(id: { eq: $id }) {
      ...Lawyer
    }
    sanityLawyerPage {
      sendAMessageForm {
        introText
        _rawWarningText(resolveReferences: { maxDepth: 6 })
        bottomNoteText
      }
      bookACallMessageForm {
        introText
        _rawWarningText(resolveReferences: { maxDepth: 6 })
        bottomNoteText
      }
    }
  }
`;

interface LawyerPageProps {
  data: {
    sanityLawyer: LawyerCharacteristics;
    sanityLawyerPage?: {
      sendAMessageForm?: {
        introText?: string;
        _rawWarningText?: RawPortableText;
        bottomNoteText?: string;
      };
      bookACallMessageForm?: {
        introText?: string;
        _rawWarningText?: RawPortableText;
        bottomNoteText?: string;
      };
    };
  };
}

const LawyerPage = ({ data }: LawyerPageProps): React.ReactElement => {
  const {
    _id,
    name,
    slug,
    hubspotInternalValue,
    image,
    personName,
    personImage,
    mainVideoUrl,
    approved,
    introText,
    _rawDescription,
    bookIntroCallUrl,
    gvApplicationsSubmitted,
    price,
    scale,
    femalePartnersPercentage,
    areasOfExpertise,
    countriesWithOffices,
    seo,
  } = data.sanityLawyer;

  const lawyerCharacteristics: Array<{ title: string; value: string | React.ReactElement }> = [
    { title: 'Auto Book Intro Call', value: bookIntroCallUrl && renderBoolean(!!bookIntroCallUrl) },
    { title: 'GV Applications Submitted', value: gvApplicationsSubmitted },
    { title: 'Price', value: price && renderPrice(price, styles) },
    { title: 'Number of Partners', value: renderLawyerScale(scale) },
    { title: 'Percentage of Female Partners', value: femalePartnersPercentage },
    { title: 'Areas Of Expertise', value: renderAreasOfExpertise(areasOfExpertise) },
    { title: 'Countries With Offices', value: renderCountries(countriesWithOffices) },
  ];

  return (
    <EntityPage
      entityType="lawyer"
      entityId={_id}
      slug={slug.current}
      hubspotInternalValue={hubspotInternalValue}
      isApproved={approved}
      hasResponsiblePerson={true}
      personImage={personImage}
      personName={personName}
      image={image}
      topic={'lawyer'}
      title={name}
      text={introText}
      content={_rawDescription}
      characteristics={lawyerCharacteristics}
      videoUrl={mainVideoUrl}
      hasReviews={ENTITY_TYPES_WITH_REVIEWS.includes('lawyer')}
      sendAMessageFormTexts={data.sanityLawyerPage?.sendAMessageForm}
      bookACallFormTexts={data.sanityLawyerPage?.bookACallMessageForm}
      bookIntroCallUrl={bookIntroCallUrl}
      seo={seo}
    />
  );
};

export default LawyerPage;
